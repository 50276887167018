jQuery(function($) {

    $(window).scroll(function() {

        scrolltop = $(window).scrollTop();
        scrollwindow = scrolltop;

        //Hero-header
        $(".hero-accueil #slideshow").css("top", scrolltop / 6 + "px");

        $(".hero-section").css("backgroundPositionY", 'calc(50% + ' + (scrolltop / 12) + 'px)');

        // $(".page .content .entry-header img").css("top", scrolltop / 6 + "px");

        // Section Below Header
        $(".parallax-section.below-header").css("backgroundPositionY", -(scrolltop / 6) + "px");


        // Section Above Footer
        if ($(".layout-hero").length !== 0 && scrollwindow > $(".layout-hero ").offset().top) {

            backgroundscroll = scrollwindow - $(".layout-hero").offset().top;
            $(".layout-hero img").css("top", backgroundscroll / 6 + "px");
            $(".layout-hero .entry-meta").css("opacity", ($(window).height() - scrollwindow * 2) / $(window).height());

        }

    });

});